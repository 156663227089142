// Define the config interface
export interface Config {
  mode: 'development' | 'production' | 'test';
  brand: {
    name: string;
    shortName: string;
    logoUrl: string;
  };
  pricingAndPromotion: {
    discountedRate: {
      amount: number;
      display: string;
    };
    standardRate: {
      amount: number;
      display: string;
    };
    membership: {
      monthlyRate: {
        amount: number;
        display: string;
      };
      minimumDurationMonths: number;
      name: string;
    };
  };
  legal: {
    termsOfServiceUrl: string;
    privacyPolicyUrl: string;
    copyright: {
      symbol: string;
      year: number;
      owner: string;
    };
  };
  integrations: {
    chargebee: {
      env: 'live' | 'test';
      test: {
        site: string;
        publishableKey: string;
      };
      live: {
        site: string;
        publishableKey: string;
      };
      items: Record<string, {
        itemPriceId: string;
        invoiceSpec: Record<string, any>;
        title: string;
        price: string;
        originalPrice: string;
        discountPercentage: string;
        description: string;
        default?: boolean;
        mostPopular?: boolean;
      }>;
    };
    reviews: {
      source: 'xano' | 'local';
    };
  };
  routing: {
    orderSuccess: string;
    schedule: string;
    login: string;
  };
  zipCodeServiceArea: {
    allowedZipCodes: string[];
    allowedMessage: string;
    notAllowedMessage: string;
  };
  session: Record<string, unknown>;
  seo: {
    description: string;
  };
  content: {
    form: {
      steps: string[];
    };
    reviews: {
      fallbackRating: number;
      fallbackCount: number;
    };
    vouchers: {
      stockIndicators: {
        lowStockThreshold: number;
        lowStockThresholdPercentage: number;
        lowStockThresholdType: 'absolute' | 'percentage';
      };
      copy: {
        highlightBadge: string;
        progress: {
          available: string;
          lowStock: string;
          soldOut: string;
        };
        notifyMeForm: {
          notifyMeButton: string;
          notifyMeButtonSubmit: string;
          description: string;
          successMessage: string;
          errorMessage: string;
        };
      };
    };
    countdownTimer: {
      initialMinutes: number;
      initialSeconds: number;
      expirationBehavior: string;
      expirationRedirectUrl: string;
      expirationMessage: string;
    };
  };
}

// Local fallback configuration
export const localConfig: Config = {
  mode: "development",
  brand: {
    name: "Baltimore Home Cleaning",
    shortName: "BHC",
    logoUrl: "/images/color_logo_transparent_background_362.png",
  },
  pricingAndPromotion: {
    discountedRate: {
      amount: 25,
      display: "$25/hr",
    },
    standardRate: {
      amount: 75,
      display: "$75/hr",
    },
    membership: {
      monthlyRate: {
        amount: 49,
        display: "$49/month",
      },
      minimumDurationMonths: 6,
      name: "EverClean",
    },
  },
  legal: {
    termsOfServiceUrl: "https://baltimorehomecleaning.com/terms",
    privacyPolicyUrl: "https://baltimorehomecleaning.com/privacy-policy",
    copyright: {
      symbol: "©",
      year: new Date().getFullYear(),
      owner: "DOMESTICLEAN LLC",
    },
  },
  integrations: {
    chargebee: {
      env: 'test',
      test: {
        site: 'domesticlean-test',
        publishableKey: 'test_ccdrMBotFZ4h2kcdRIgQ4BSIcu9BslFRTVg',
      },
      live: {
        site: 'domesticlean',
        publishableKey: 'live_wr68P4YdcuTswQ8lJQhDlKn415KXeDpxq',
      },
      items: {
        1: {
          itemPriceId: "2-Hours-of-Cleaning-USD",
          invoiceSpec: {
            item_prices: [
              { item_price_id: "2-Hours-of-Cleaning-USD" },
            ],
            coupon_ids: ["2HRVOUCHER41"], // Production coupon
          },
          title: "2 Hours of Cleaning",
          price: "9",
          originalPrice: "50",
          discountPercentage: "82",
          description: "Ideal for quick touch-ups",
          default: false,
        },
        2: {
          itemPriceId: "3-Hours-of-Cleaning-USD",
          invoiceSpec: {
            item_prices: [
              { item_price_id: "3-Hours-of-Cleaning-USD" },
            ],
            coupon_ids: ["3HRVOUCHER19"], // Example
          },
          title: "3 Hours of Cleaning",
          price: "19",
          originalPrice: "75",
          discountPercentage: "75",
          description: "Ideal for 1 bed/1 bath homes",
          default: true,
          mostPopular: true,
        },
        3: {
          itemPriceId: "4-Hours-of-Cleaning-USD",
          invoiceSpec: {
            item_prices: [
              { item_price_id: "4-Hours-of-Cleaning-USD" },
            ],
            coupon_ids: ["4HRVOUCHER39"],
          },
          title: "4 Hours of Cleaning",
          price: "39",
          originalPrice: "100",
          discountPercentage: "61",
          description: "Ideal for 2 bed/2 bath homes",
        },
        4: {
          itemPriceId: "6-Hours-of-Cleaning-USD",
          invoiceSpec: {
            item_prices: [
              { item_price_id: "6-Hours-of-Cleaning-USD" },
            ],
            coupon_ids: ["6HRVOUCHER79"],
          },
          title: "6 Hours of Cleaning",
          price: "79",
          originalPrice: "150",
          discountPercentage: "47",
          description: "Ideal for larger homes",
        },
      },
    },
    reviews: {
      source: "local",
    },
  },
  routing: {
    orderSuccess: '/survey',
    schedule: '/',
    login: 'https://baltimorehomecleaning.com/',
  },
  zipCodeServiceArea: {
    allowedZipCodes: [
      "21201",
      "21231",
      "21224",
      "21211",
      "21230",
    ],
    allowedMessage: 'Great news! This offer is available in your area.',
    notAllowedMessage: 'Not in your area... yet! Share your details to help us bring this offer to your neighborhood sooner—and we\'ll keep you updated!',
  },
  session: {},
  seo: {
    description: "Baltimore Home Cleaning offers professional cleaning services for homes and businesses in the Baltimore area. Book online with ease and enjoy a cleaner home today!",
  },
  content: {
    form: {
      steps: ["voucher", "contact", "notices", "payment"]
    },
    reviews: {
      fallbackRating: 4.6,
      fallbackCount: 8027,
    },
    vouchers: {
      stockIndicators: {
        lowStockThreshold: 10,
        lowStockThresholdType: 'percentage',
        lowStockThresholdPercentage: 50,
      },
      copy: {
        highlightBadge: "Most Popular",
        progress: {
          available: 'Available',
          lowStock: '⚡️ Hurry - only {quantity} left',
          soldOut: '🚫 Sold out',
        },
        notifyMeForm: {
          notifyMeButton: "Notify me when it's back",
          notifyMeButtonSubmit: "Notify Me",
          description: "Enter your email, and we'll let you know as soon as we release new vouchers:",
          successMessage: "✅ We'll notify you as soon as new vouchers drop!",
          errorMessage: "Something went wrong. Please try again.",
        },
      },
    },
    countdownTimer: {
      initialMinutes: 10,
      initialSeconds: 0,
      expirationBehavior: 'reset',
      expirationRedirectUrl: 'https://baltimorehomecleaning.com',
      expirationMessage: 'Sorry, your session has expired. Please refresh the page to start over.',
    },
  }
};

// Import useConfig for the proxy
import { useConfig } from './config-context';

// Create a proxy for backward compatibility
const configProxy = new Proxy({} as ReturnType<typeof useConfig>, {
  get(target, prop) {
    // Get the config from context
    const config = useConfig();
    // Return the requested property
    return config[prop as keyof typeof config];
  },
});

// Export the proxy as 'config' for backward compatibility
export const config = configProxy;

// Export a convenience function to get the Chargebee config
// Type for Chargebee environment config
interface ChargebeeEnvConfig {
  site: string;
  publishableKey: string;
}

// Hook version for use inside components
export function useChargebeeConfig(): ChargebeeEnvConfig {
  const config = useConfig();
  return getChargebeeConfigFromConfig(config);
}

// Non-hook version for use outside components
export function getChargebeeConfigFromConfig(config: Config): ChargebeeEnvConfig {
  const env = config.mode === "production" ? "live" : "test";
  const chargebeeConfig = config.integrations.chargebee[env];

  if (!chargebeeConfig?.site || !chargebeeConfig?.publishableKey) {
    throw new Error('Invalid Chargebee configuration');
  }

  return {
    site: chargebeeConfig.site,
    publishableKey: chargebeeConfig.publishableKey
  };
}