// theme.ts
import {
  Container,
  createTheme,
  DEFAULT_THEME,
  mergeMantineTheme,
  rem,
  Text,
} from "@mantine/core";

const CONTAINER_SIZES = {
  xs: rem(540),
  sm: rem(720),
  md: rem(960),
  lg: rem(1140),
  xl: rem(1320),
};

const themeOverride = createTheme({
  primaryColor: "green",
  fontSizes: {
    xs: rem(12),
    sm: rem(14),
    md: rem(16),
    lg: rem(18),
    xl: rem(20),
  },
  headings: {
    fontWeight: "600",
    sizes: {
      h1: { fontSize: rem(36), lineHeight: "1.4" },
      h2: { fontSize: rem(30), lineHeight: "1.4" },
      h3: { fontSize: rem(24), lineHeight: "1.4" },
      h4: { fontSize: rem(20), lineHeight: "1.4" },
      h5: { fontSize: rem(16), lineHeight: "1.4" },
      h6: { fontSize: rem(14), lineHeight: "1.4" },
    },
  },
  breakpoints: {
    xs: "36em", // 576px
    sm: "48em", // 768px
    md: "62em", // 992px
    lg: "75em", // 1200px
    xl: "88em", // 1400px
  },
  spacing: {
    xs: rem(4),
    sm: rem(8),
    md: rem(16),
    lg: rem(24),
    xl: rem(32),
    xxl: rem(75),
  },
  components: {
    // Container: Container.extend({ 
    //     defaultProps: { sizes: CONTAINER_SIZES } 
    // }),
    Text: Text.extend({
      defaultProps: { size: "md" }
    }),
  },
});

export const theme = mergeMantineTheme(DEFAULT_THEME, themeOverride);