import { createContext, useContext, useState } from 'react';
import type { Config } from './config-candidate';
import { localConfig } from './config-candidate';

const ConfigContext = createContext<Config | null>(null);

interface ConfigProviderProps {
  children: React.ReactNode;
  initialConfig?: Config;
}

export function ConfigProvider({ children, initialConfig }: ConfigProviderProps) {
  // Use initialConfig from loader or fall back to localConfig
  const [config] = useState<Config>(initialConfig || localConfig);

  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
}